/*
body {
    background: hsl(217.6,100%,80%);
    font-family: monospace;
    padding-top: 5em;
    display: flex;
    flex-flow: wrap column;
    justify-content: center;
    align-items: center;
  }
*/
  .typewriter {
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
  }
  
  .typewriter svg {
    padding-right: 2em;
  }
  
  .typewriter p {
    width: 0;
    max-width: max-content;
    overflow: hidden; 
    border-right: .05em solid #464545; /* The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0; /* Gives that scrolling effect as the typing happens */
  }
  
  .typing-erase {
    animation: 
      4s typing-erase 4s steps(50, end) infinite,
      blink-caret .5s step-end infinite;
  }
  
  /* The type and erase effect */
  @keyframes typing-erase {
    0% { width: 0 }
    80% { width: 100% }
    90%, 100% { width: 0 }
  }
  
  /* The typewriter cursor effect */
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: gray }
  }